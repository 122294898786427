import React, { useState } from "react";

import ReactPlayer from 'react-player';
import { Icon } from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive';

import HeaderComponent from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ImageCarousel from "../Homepage/ImageCarousel/ImageCarousel";

import { atelierDetalii2 } from "../../utils/dummyArray";
import main from '../../utils/videos/main.mp4'
import { primaryGrey} from '../../utils/colors'

import PisicaVerde from '../../utils/images/PisicaVerde.jpg'

import Pic1 from '../../utils/Proiect2024/1.jpg'
import Pic2 from '../../utils/Proiect2024/2.jpg'
import Pic3 from '../../utils/Proiect2024/3.jpg'
import Pic4 from '../../utils/Proiect2024/4.jpg'
import Pic5 from '../../utils/Proiect2024/5.jpg'
import Pic6 from '../../utils/Proiect2024/6.jpg'
import Pic7 from '../../utils/Proiect2024/7.jpg'
import Pic8 from '../../utils/Proiect2024/8.jpg'
import Pic9 from '../../utils/Proiect2024/9.jpg'
import Pic10 from '../../utils/Proiect2024/10.jpg'
import Pic11 from '../../utils/Proiect2024/11.jpg'
import Pic12 from '../../utils/Proiect2024/12.jpg'
import Pic13 from '../../utils/Proiect2024/13.jpg'
import Pic14 from '../../utils/Proiect2024/14.jpg'
import Pic15 from '../../utils/Proiect2024/15.jpg'
import Pic16 from '../../utils/Proiect2024/16.jpg'
import Pic17 from '../../utils/Proiect2024/17.jpg'
import Pic18 from '../../utils/Proiect2024/18.jpg'
import Pic19 from '../../utils/Proiect2024/19.jpg'
import Pic20 from '../../utils/Proiect2024/20.jpg'
import Pic21 from '../../utils/Proiect2024/21.jpg'
import Pic22 from '../../utils/Proiect2024/22.jpg'
import Pic23 from '../../utils/Proiect2024/23.jpg'


import APic1 from '../../utils/Proiect20242/1.jpg'
import APic2 from '../../utils/Proiect20242/2.jpg'
import APic3 from '../../utils/Proiect20242/3.jpg'
import APic4 from '../../utils/Proiect20242/4.jpg'
import APic5 from '../../utils/Proiect20242/5.jpg'
import APic6 from '../../utils/Proiect20242/6.jpg'
import APic7 from '../../utils/Proiect20242/7.jpg'
import APic8 from '../../utils/Proiect20242/8.jpg'
import APic9 from '../../utils/Proiect20242/9.jpg'
import APic10 from '../../utils/Proiect20242/10.jpg'
import APic11 from '../../utils/Proiect20242/11.jpg'
import APic12 from '../../utils/Proiect20242/12.jpg'
import APic13 from '../../utils/Proiect20242/13.jpg'
import APic14 from '../../utils/Proiect20242/14.jpg'
import APic15 from '../../utils/Proiect20242/15.jpg'
import APic16 from '../../utils/Proiect20242/16.jpg'
import APic17 from '../../utils/Proiect20242/17.jpg'
import APic18 from '../../utils/Proiect20242/18.jpg'
import APic19 from '../../utils/Proiect20242/19.jpg'
import APic20 from '../../utils/Proiect20242/20.jpg'
import APic21 from '../../utils/Proiect20242/21.jpg'

import Trailer from '../../utils/videos/Trailer.mp4'


import './Proiect.css';


export const imageCarousel = [
    {
        id: 1, 
        src: Pic1,
        alt: 'one',
    },
    {
        id: 2, 
        src: Pic2,
        alt: 'two',
    },
    {
        id: 3, 
        src: Pic3,
        alt: 'three',
    },
    {
        id: 4, 
        src: Pic4,
        alt: 'four',
    },,
    {
        id: 5, 
        src: Pic5,
        alt: 'five',
    },
    {
        id: 6, 
        src: Pic6,
        alt: 'six',
    },
    {
        id: 7, 
        src: Pic7,
        alt: 'seven',
    },
    {
        id: 8, 
        src: Pic8,
        alt: 'eight',
    },
    {
        id: 9, 
        src:  Pic9,
        alt: 'nine',
    },
    {
        id: 10, 
        src: Pic10,
        alt: 'ten',
    },
    {
        id: 11, 
        src: Pic11,
        alt: 'eleven',
    },
    {
      id: 12, 
      src: Pic12,
      alt: 'eleven',
    },
    {
        id: 13, 
        src: Pic13,
        alt: 'eleven',
      },
      {
        id: 14, 
        src: Pic14,
        alt: 'eleven',
      },
      {
        id: 15, 
        src: Pic15,
        alt: 'eleven',
      },
      {
        id: 16, 
        src: Pic16,
        alt: 'eleven',
      },
      {
        id: 17, 
        src: Pic17,
        alt: 'eleven',
      },
      {
        id: 18, 
        src: Pic18,
        alt: 'eleven',
      },
      {
        id: 19, 
        src: Pic19,
        alt: 'eleven',
      },{
        id: 20, 
        src: Pic20,
        alt: 'eleven',
      },
      {
        id: 21, 
        src: Pic21,
        alt: 'eleven',
      },
      {
        id: 22, 
        src: Pic22,
        alt: 'eleven',
      },
      {
        id: 23, 
        src: Pic23,
        alt: 'eleven',
      },
]

export const imageCarousel2 = [
    {
        id: 1, 
        src: APic1,
        alt: 'one',
    },
    {
        id: 2, 
        src: APic2,
        alt: 'two',
    },
    {
        id: 3, 
        src: APic3,
        alt: 'three',
    },
    {
        id: 4, 
        src: APic4,
        alt: 'four',
    },,
    {
        id: 5, 
        src:APic5,
        alt: 'five',
    },
    {
        id: 6, 
        src: APic6,
        alt: 'six',
    },
    {
        id: 7, 
        src: APic7,
        alt: 'seven',
    },
    {
        id: 8, 
        src: APic8,
        alt: 'eight',
    },
    {
        id: 9, 
        src:  APic9,
        alt: 'nine',
    },
    {
        id: 10, 
        src: APic10,
        alt: 'ten',
    },
    {
        id: 11, 
        src: APic11,
        alt: 'eleven',
    },
    {
      id: 12, 
      src: APic12,
      alt: 'eleven',
    },
    {
        id: 13, 
        src: APic13,
        alt: 'eleven',
      },
      {
        id: 14, 
        src: APic14,
        alt: 'eleven',
      },
      {
        id: 15, 
        src: APic15,
        alt: 'eleven',
      },
      {
        id: 16, 
        src: APic16,
        alt: 'eleven',
      },
      {
        id: 17, 
        src: APic17,
        alt: 'eleven',
      },
      {
        id: 18, 
        src: APic18,
        alt: 'eleven',
      },
      {
        id: 19, 
        src: APic19,
        alt: 'eleven',
      },{
        id: 20, 
        src: APic20,
        alt: 'eleven',
      },
      {
        id: 21, 
        src: APic21,
        alt: 'eleven',
      },
]

const showData = 
    {  
        id: 1,
        src: PisicaVerde,
        title: 'Niste Zile Ciudate cu Pisica Verde',
        subtitle: 'Adaptare texte de Elise Wilk',
        description: 'Sensibilitatea acestor adolescenţi, problemele lor, întrebările, răspunsurile, tăcerile, alegerile lor se relevă pas cu pas și ne permit să intrăm în lumea în care ei există zi de zi, și să ascultăm mai atent lucrurile prin care trec: evadarea din viaţa reală într-una imaginară, probleme în familie, bullying-ul pe rețele, prima iubire, relațiile dintre adolescenți, anxietatea și depresia. ',
        description2: 'Spectacol bilingv, cu supratitrare',
        description3: 'Spectacolul e urmat de o sesiune Q&A moderata de un psiholog.',
        hour: '18:00',
        date: '16 FEB',
        address: 'Turnul portii, Cetatea Medievala',
        price: '30RON adulti & 15RON elevi'
    }

const Proiect2 = ({color}) => {

    const [activeSection, setActiveSection] = useState("spectacol");
    
        const handleSectionChange = (section) => {
          setActiveSection(section);
        };
    

    const sm = useMediaQuery({ query: "(max-width:400px)" })
    const sm2 = useMediaQuery({ query: "(max-width:500px)" })
    const md = useMediaQuery({ query: "(max-width:600px)" })
    const md2 = useMediaQuery({ query: "(max-width:800px)" })
    const xxl = useMediaQuery({ query: "(max-width:900px)" })
    const xxl2 = useMediaQuery({ query: "(min-width:1500px)" })

    const playerWidth = () => {
        if (sm) {
            return 250;
        } else if (sm2) {
            return 350;
        } else if (md) {
            return 400;
        } else if (md2) {
            return 500;
        } else if (xxl) {
            return 700;
        } else if (xxl2) {
            return 1200;
        } else {
            return 1000;
        }
    };

    const titleSize = () => {
        if(sm) { 
            return 16
        } else if(sm2) {
            return 20
        } else if(md2) {
            return 22
        } else if(xxl) {
            return 38
        } else if(xxl2) {
            return 38
        }
    }

    const subTitleSize = () => {
        if(sm) { 
            return 12
        } else if(sm2) {
            return 14
        } else if(md2) {
            return 16
        } else if(xxl) {
            return 18
        } else if(xxl2) {
            return 22
        }
    } 


    const imageWidth = () => {
        if(sm) { 
            return 60
        } else if(sm2) {
            return 100
        } else if(md2) {
            return 120
        } else if(xxl) {
            return 140
        } else if(xxl2) {
            return 150
        }
    }

    const imageHeight = () => {
        if(sm) { 
            return 60
        } else if(sm2) {
            return 100
        } else if(md2) {
            return 120
        } else if(xxl) {
            return 140
        } else if(xxl2) {
            return 150
        }
    }

    const nameSize = () => {
        if(sm) { 
            return 14
        } else {
            return 18
        }
    }

    const descriptionSize = () => {
        if(sm) { 
            return 10
        } else if(sm2) {
            return 12
        } else {
            return 14
        }
    }

    const cvSize = () => {
        if(sm) { 
            return 10
        } else if(sm2) {
            return 12
        } else {
            return 16
        }
    }


    return (
        <div >
            <HeaderComponent />

            <div className="main-container-content">
                <h1 className='elise'
                    style={{fontSize: titleSize(), color: 'rgb(115, 117, 111)'}}
                >Exploziv ART (2024) </h1>
                <h2 className='incub-2' style={{fontSize: subTitleSize()}}>Exploziv ART(ediția 2) este un proiect de eduație nonformala, bilingv, care se adresează elevilor din  liceele târgumureșene. </h2>
                <h2 className='incub-2' style={{fontSize: subTitleSize()}}>Printr-o serie de ateliere interdisciplinare urmate de producerea unui  spectacol de teatru, urmărim implicarea lor în activități practice și interactive care să le dezvolte competențe necesare multor arii de activitate și să le oferim un mediu în care să poată vorbi despre problemele specifice vârstei lor simțindu-se in siguranță. </h2>
            </div>

            <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1rem",
                    marginTop: "2rem",
                }}
            >
                <button onClick={() => handleSectionChange("ateliere")} style={{height: '2.5rem', width: '7rem', borderRadius: 30, color: '#923b16', fontWeight: 'bold', cursor: 'pointer'}}>Ateliere</button>
                <button onClick={() => handleSectionChange("spectacol")} style={{height: '2.5rem', width: '7rem', borderRadius: 30, color: '#923b16', fontWeight: 'bold', cursor: 'pointer'}}>Spectacol</button>
            </div>


           {activeSection === "ateliere" && ( 
                <div> 
                    {atelierDetalii2 && Object.values(atelierDetalii2).map((item, index) => (
                        <div className="container-atelier-left" key={index}>
                            <div className="image-container-atelier-left">
                                <img src={item.img} alt="" style={{width: imageWidth(), height: imageHeight()}}/>
                            </div>
                            <div className="information-container-atelier-left" style={{lineHeight: 0.1}}>
                                <h3 style={{fontSize: nameSize()}}>{item.title}</h3>
                                <h5 style={{fontSize: descriptionSize()}}>{item.descriere}</h5>
                                <h5 style={{fontSize: descriptionSize()}}>{item.desscriere1}</h5>
                                <h5 style={{fontSize: descriptionSize()}}>{item.descriere2}</h5>
                                <h5 style={{fontSize: descriptionSize()}}>{item.descriere3}</h5>
                                <h5 style={{fontSize: descriptionSize()}}>{item.descriere4}</h5>
                                <h5 style={{fontSize: descriptionSize()}}>{item.descriere5}</h5>
                                <h5 style={{fontSize: descriptionSize()}}>{item.descriere6}</h5>

                                {item.name !== "" ? (
                                    <a href={`/cv/${item.name}`} className="link-cv" style={{fontSize: cvSize()}}>
                                    Spre CV coordonator
                                    </a>
                                ) : null}
                            </div>
                        </div>
                    ))}

                    <div className="center aligned" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '5rem', marginTop: '5rem' }}>
                        <ReactPlayer 
                            url={main} 
                            // height={ xxl ? 550 : 500 } 
                            width={ playerWidth() }
                            controls={true}
                            playing={true}
                            loop={true}
                            muted={true}
                            onReady={() => true}
                        />
                    </div>

                    <ImageCarousel data={imageCarousel} title={"Galerie"}/>
                </div> 
            )}


        {activeSection === "spectacol" && ( 
            <div>
                <div className="container-atelier-left">
                    <div 
                        style={{
                            backgroundColor: primaryGrey,
                            display: xxl? '' : 'flex',
                            width: '80%',
                            margin: 'auto',
                            marginBottom: '10vh',
                        }}
                    >

                        <div className="left">
                            <img  src={showData?.src} alt={showData?.title} height={640} width={1000}/>
                        </div>

                        <div style={{textAlign: 'left', marginLeft: '2rem'}}>
                            <p style={{fontSize: 30}}>{showData?.title}</p>
                            <p style={{fontSize: 20, marginTop: '-30px'}}>{showData?.subtitle}</p>
                            <p className="description-a"> {showData?.description}</p>
                            <p className="description-a"> {showData?.description1}</p>
                            <p className="description-a"> {showData?.description2}</p>
                            <p className="description-a"> {showData?.description3}</p>
                            <p className="description-a"> {showData?.description4}</p>

                            <div className="dates">
                                <span>{showData?.hour}</span>
                                <span>{showData?.date}  {showData?.month}</span>
                            </div>


                                <div className="address">
                                    <Icon name='map marker alternate icon' style={{backgroundColor: primaryGrey}}/>
                                    <span style={{ fontSize: '18px'}}>{showData?.address}</span>
                                </div>

                                {showData?.price &&
                                    <div className="pay">
                                        <Icon name='money bill alternate outline icon'style={{backgroundColor: primaryGrey, marginRight: '15px'}}/>
                                        <span style={{ fontSize: '18px'}}>{showData?.price}</span>
                                    </div>
                                }
                                
                                <p style={{fontSize: '25px', marginTop: '30px'}}>Rezervari: <span className="mail">incubator13ro@gmail.com</span></p>

                        </div>

                        
                    </div>
                </div>

                <div className="center aligned" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '5rem', marginTop: '5rem' }}>
                    <ReactPlayer 
                        url={Trailer} 
                        // height={ xxl ? 550 : 500 } 
                        width={ playerWidth() }
                        controls={true}
                        playing={true}
                        loop={true}
                        muted={true}
                        onReady={() => true}
                    />
                </div> 

                <ImageCarousel data={imageCarousel2} title={"Galerie"}/>
           

                </div>
            )}


            <div style={{ width: '60%', margin: 'auto', marginBottom: '2rem', textAlign: 'center'}}>
                <h5 style={{fontSize: descriptionSize()}}>Proiect co-finanțat de Municipiul Târgu Mureş în sesiunea 2024.</h5>
                <h5 style={{fontSize: descriptionSize()}}>Proiectul nu reprezintă în mod necesar poziția Municipiului Târgu Mureş şi a Consiliului local care nu sunt responsabili de conţinutul proiectului sau de modul în care rezultatele acestuia pot fi folosite. Acestea sunt în întregime responsabilitatea beneficiarului finanţării”</h5>
            </div>

            <Footer />
        </div>

    )
}


export default Proiect2;