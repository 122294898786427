import React from 'react'


import HeaderComponent from '../../components/Header/Header'
import Herosection from '../../components/Herosection/Herosection';
import ImageCarousel from './ImageCarousel/ImageCarousel';
import HomepageShows from './HomepageShows/HomepageShows'
import HomepageReviews from './HomepageReviews/HomepageReviews';
import Footer from '../../components/Footer/Footer';

import { imageCarousel } from '../../utils/dummyArray';
import HompeageMuzeu from '../../components/HomepageMuzeu/HomepageMuzeu';
import Bikeathon from '../../components/Bikeathon/Bikeathon';


const Homepage = () => {

  return (
    <>
        <HeaderComponent />
        <Herosection />
        {/* <Bikeathon /> */}
        {/* <HompeageMuzeu /> */}
        <HomepageShows />
        <ImageCarousel data={imageCarousel} title={"Din spectacolele anterioare"}/>
        <HomepageReviews />

        <div style={{
          width: '80%',
          margin: 'auto',
          color: '#73756f',
          marginBottom: '6rem'
        }}>
          <p style={{fontSize: '22px'}}>Dragii noștri spectatori și prieteni,</p>
          <br/>
          <p style={{fontSize: '22px'}}>Teatrul nostru există datorită pasiunii pentru artă și a dorinței de a aduce bucurie în sufletele voastre. Fiecare spectacol, fiecare repetiție și fiecare proiect sunt posibile datorită sprijinului vostru generos. Dacă v-ați bucurat de reprezentațiile noastre și doriți să ne ajutați să continuăm să aducem magia teatrului în viețile voastre și să ne ajutați să ducem mai departe proiectul ExplozivART, vă invităm să faceți o donație.</p>
          <p style={{fontSize: '22px'}}>Fiecare contribuție, oricât de mică, contează și ne ajută să creștem și să oferim proiecte și spectacole de calitate.</p>
          <br/>
          <p style={{fontSize: '22px'}}>Vă mulțumim din suflet pentru sprijinul vostru!</p>
          <p style={{fontSize: '22px', textDecoration: 'underline'}}><a href="https://formular230.ro/asociatia-incubator13" target="_blank">Redirectioneaza 3,5% aici</a></p>
          <p style={{fontSize: '22px'}}><a target="_blank">Donează aici: <b> RO27BACX0000001703961001 - Asociatia INCUBATOR13</b></a></p>
          <p style={{fontSize: '22px'}}>Cu recunoștință,</p>
          <p style={{fontSize: '22px'}}>Echipa Incubator13</p>
        </div>
        <Footer /> 
    </>
  )
}

export default Homepage;
