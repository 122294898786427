import React, { useState }  from "react";

import HeaderComponent from "../../components/Header/Header";

import { atelierDetalii } from "../../utils/dummyArray";
import ReactPlayer from 'react-player';

import { useMediaQuery } from 'react-responsive';

import trailerExploziv from '../../utils/videos/trailerExploziv.mp4'
import { primaryGrey} from '../../utils/colors'


import './Proiect.css';
import Footer from "../../components/Footer/Footer";
import ExpAfis from '../../utils/images/exploziv/ExpAfis.jpg'

import ImageCarousel from "../Homepage/ImageCarousel/ImageCarousel";

import Pic1 from '../../utils/Proiect2023/1.jpg'
import Pic2 from '../../utils/Proiect2023/2.jpg'
import Pic3 from '../../utils/Proiect2023/3.jpg'
import Pic4 from '../../utils/Proiect2023/4.jpg'
import Pic5 from '../../utils/Proiect2023/5.jpg'
import Pic6 from '../../utils/Proiect2023/6.jpg'
import Pic7 from '../../utils/Proiect2023/7.jpg'
import Pic8 from '../../utils/Proiect2023/8.jpg'
import Pic9 from '../../utils/Proiect2023/9.jpg'
import Pic10 from '../../utils/Proiect2023/10.jpg'
import Pic11 from '../../utils/Proiect2023/11.jpg'
import Pic12 from '../../utils/Proiect2023/12.jpg'
import Pic13 from '../../utils/Proiect2023/13.jpg'
import Pic14 from '../../utils/Proiect2023/14.jpg'
import Pic15 from '../../utils/Proiect2023/15.jpg'
import Pic16 from '../../utils/Proiect2023/16.jpg'
import Pic17 from '../../utils/Proiect2023/17.jpg'
import Pic18 from '../../utils/Proiect2023/18.jpg'
import Pic19 from '../../utils/Proiect2023/19.jpg'
import Pic20 from '../../utils/Proiect2023/20.jpg'
import Pic21 from '../../utils/Proiect2023/21.jpg'
import Pic22 from '../../utils/Proiect2023/22.jpg'
import Pic23 from '../../utils/Proiect2023/23.jpg'
import Pic24 from '../../utils/Proiect2023/24.jpg'
import Pic25 from '../../utils/Proiect2023/25.jpg'



import APic1 from '../../utils/Proiect20232/1.jpg'
import APic2 from '../../utils/Proiect20232/2.jpg'
import APic3 from '../../utils/Proiect20232/3.jpg'
import APic4 from '../../utils/Proiect20232/4.jpg'
import APic5 from '../../utils/Proiect20232/5.jpg'
import APic6 from '../../utils/Proiect20232/6.jpg'
import APic7 from '../../utils/Proiect20232/7.jpg'
import APic8 from '../../utils/Proiect20232/8.jpg'
import APic9 from '../../utils/Proiect20232/9.jpg'
import APic10 from '../../utils/Proiect20232/10.jpg'
import APic11 from '../../utils/Proiect20232/11.jpg'
import APic12 from '../../utils/Proiect20232/12.jpg'
import APic13 from '../../utils/Proiect20232/13.jpg'
import APic14 from '../../utils/Proiect20232/14.jpg'
import APic15 from '../../utils/Proiect20232/15.jpg'
import APic16 from '../../utils/Proiect20232/16.jpg'
import APic17 from '../../utils/Proiect20232/17.jpg'
import APic18 from '../../utils/Proiect20232/18.jpg'
import APic19 from '../../utils/Proiect20232/19.jpg'
import APic20 from '../../utils/Proiect20232/20.jpg'
import APic21 from '../../utils/Proiect20232/21.jpg'
import APic22 from '../../utils/Proiect20232/22.jpg'
import APic23 from '../../utils/Proiect20232/23.jpg'
import APic24 from '../../utils/Proiect20232/24.jpg'
import APic25 from '../../utils/Proiect20232/25.jpg'
import APic26 from '../../utils/Proiect20232/26.jpg'
import APic27 from '../../utils/Proiect20232/27.jpg'

import Impact from '../../utils/videos/Impact.mp4'

export const imageCarousel = [
    {
        id: 1, 
        src: Pic1,
        alt: 'one',
    },
    {
        id: 2, 
        src: Pic2,
        alt: 'two',
    },
    {
        id: 3, 
        src: Pic3,
        alt: 'three',
    },
    {
        id: 4, 
        src: Pic4,
        alt: 'four',
    },,
    {
        id: 5, 
        src: Pic5,
        alt: 'five',
    },
    {
        id: 6, 
        src: Pic6,
        alt: 'six',
    },
    {
        id: 7, 
        src: Pic7,
        alt: 'seven',
    },
    {
        id: 8, 
        src: Pic8,
        alt: 'eight',
    },
    {
        id: 9, 
        src:  Pic9,
        alt: 'nine',
    },
    {
        id: 10, 
        src: Pic10,
        alt: 'ten',
    },
    {
        id: 11, 
        src: Pic11,
        alt: 'eleven',
    },
    {
      id: 12, 
      src: Pic12,
      alt: 'eleven',
    },
    {
        id: 13, 
        src: Pic13,
        alt: 'eleven',
      },
      {
        id: 14, 
        src: Pic14,
        alt: 'eleven',
      },
      {
        id: 15, 
        src: Pic15,
        alt: 'eleven',
      },
      {
        id: 16, 
        src: Pic16,
        alt: 'eleven',
      },
      {
        id: 17, 
        src: Pic17,
        alt: 'eleven',
      },
      {
        id: 18, 
        src: Pic18,
        alt: 'eleven',
      },
      {
        id: 19, 
        src: Pic19,
        alt: 'eleven',
      },{
        id: 20, 
        src: Pic20,
        alt: 'eleven',
      },
      {
        id: 21, 
        src: Pic21,
        alt: 'eleven',
      },
      {
        id: 22, 
        src: Pic22,
        alt: 'eleven',
      },
      {
        id: 23, 
        src: Pic23,
        alt: 'eleven',
      },{
        id: 24, 
        src: Pic24,
        alt: 'eleven',
      },{
        id: 25, 
        src: Pic25,
        alt: 'eleven',
      },
   
]

export const imageCarousel2 = [
    {
        id: 1, 
        src: APic1,
        alt: 'one',
    },
    {
        id: 2, 
        src: APic2,
        alt: 'two',
    },
    {
        id: 3, 
        src: APic3,
        alt: 'three',
    },
    {
        id: 4, 
        src: APic4,
        alt: 'four',
    },,
    {
        id: 5, 
        src:APic5,
        alt: 'five',
    },
    {
        id: 6, 
        src: APic6,
        alt: 'six',
    },
    {
        id: 7, 
        src: APic7,
        alt: 'seven',
    },
    {
        id: 8, 
        src: APic8,
        alt: 'eight',
    },
    {
        id: 9, 
        src:  APic9,
        alt: 'nine',
    },
    {
        id: 10, 
        src: APic10,
        alt: 'ten',
    },
    {
        id: 11, 
        src: APic11,
        alt: 'eleven',
    },
    {
      id: 12, 
      src: APic12,
      alt: 'eleven',
    },
    {
        id: 13, 
        src: APic13,
        alt: 'eleven',
      },
      {
        id: 14, 
        src: APic14,
        alt: 'eleven',
      },
      {
        id: 15, 
        src: APic15,
        alt: 'eleven',
      },
      {
        id: 16, 
        src: APic16,
        alt: 'eleven',
      },
      {
        id: 17, 
        src: APic17,
        alt: 'eleven',
      },
      {
        id: 18, 
        src: APic18,
        alt: 'eleven',
      },
      {
        id: 19, 
        src: APic19,
        alt: 'eleven',
      },{
        id: 20, 
        src: APic20,
        alt: 'eleven',
      },
      {
        id: 21, 
        src: APic21,
        alt: 'eleven',
      },
      {
        id: 22, 
        src: APic22,
        alt: 'eleven',
      },
      {
        id: 23, 
        src: APic23,
        alt: 'eleven',
      },{
        id: 24, 
        src: APic24,
        alt: 'eleven',
      },{
        id: 25, 
        src: APic25,
        alt: 'eleven',
      },{
        id: 26, 
        src: APic26,
        alt: 'eleven',
      },{
        id: 27, 
        src: APic27,
        alt: 'eleven',
      },
   
]


const showData = 
    {  
        id: 1,
        src: ExpAfis,
        title: 'EXPLOZIV de Elise Wilk',
        description: '“Exploziv” este un spectacol cu și despre liceeni. Este despre relația dintre ei, părinți și profesori. Este despre comunicare (sau, mai degrabă, lipsa ei) și despre dinamica acestor grupuri. ',
        description2: '„Exploziv” este un spectacol care ar trebui văzut de toate aceste categorii și nu numai.'
    }


const Proiect = ({color}) => {
    const [activeSection, setActiveSection] = useState("ateliere");

    const handleSectionChange = (section) => {
      setActiveSection(section);
    };

    const sm = useMediaQuery({ query: "(max-width:400px)" })
    const sm2 = useMediaQuery({ query: "(max-width:500px)" })
    const md = useMediaQuery({ query: "(max-width:600px)" })
    const md2 = useMediaQuery({ query: "(max-width:800px)" })
    const xxl = useMediaQuery({ query: "(max-width:900px)" })
    const xxl2 = useMediaQuery({ query: "(min-width:1500px)" })

    const playerWidth = () => {
        if (sm) {
            return 250;
        } else if (sm2) {
            return 350;
        } else if (md) {
            return 400;
        } else if (md2) {
            return 500;
        } else if (xxl) {
            return 700;
        } else if (xxl2) {
            return 1200;
        } else {
            return 1000;
        }
    };

    const titleSize = () => {
        if(sm) { 
            return 16
        } else if(sm2) {
            return 20
        } else if(md2) {
            return 22
        } else if(xxl) {
            return 38
        } else if(xxl2) {
            return 48
        }
    }

    const subTitleSize = () => {
        if(sm) { 
            return 12
        } else if(sm2) {
            return 14
        } else if(md2) {
            return 16
        } else if(xxl) {
            return 28
        } else if(xxl2) {
            return 30
        }
    } 


    const imageWidth = () => {
        if(sm) { 
            return 60
        } else if(sm2) {
            return 100
        } else if(md2) {
            return 120
        } else if(xxl) {
            return 140
        } else if(xxl2) {
            return 150
        }
    }

    const imageHeight = () => {
        if(sm) { 
            return 60
        } else if(sm2) {
            return 100
        } else if(md2) {
            return 120
        } else if(xxl) {
            return 140
        } else if(xxl2) {
            return 150
        }
    }

    const nameSize = () => {
        if(sm) { 
            return 14
        } else {
            return 18
        }
    }

    const descriptionSize = () => {
        if(sm) { 
            return 10
        } else if(sm2) {
            return 12
        } else {
            return 14
        }
    }

    const cvSize = () => {
        if(sm) { 
            return 10
        } else if(sm2) {
            return 12
        } else {
            return 16
        }
    }



    return (
        <div >
            <HeaderComponent />

            <div className="main-container-content">
                <h1 className='elise'
                    style={{fontSize: titleSize(), color: 'rgb(115, 117, 111)'}}
                >Exploziv ART (2023) </h1>
                <h2 className='incub-2' style={{fontSize: subTitleSize()}}>Exploziv ART, este  un proiect pilot de educație nonformală, care se adresează liceenilor târgumureșeni. </h2>
                <h2 className="incub-2" style={{fontSize: subTitleSize()}}>Printr-o serie de ateliere interdisciplinare urmate de producerea unui  spectacol de teatru, urmărim implicarea lor în activități practice și interactive care să le dezvolte competențe necesare multor arii de activitate și să le oferim un mediu în care să poată vorbi despre problemele specifice vârstei lor simțindu-se în siguranță.</h2>
            </div>

            <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1rem",
                    marginTop: "2rem",
                }}
            >
                <button onClick={() => handleSectionChange("ateliere")} style={{height: '2.5rem', width: '7rem', borderRadius: 30, color: '#923b16', fontWeight: 'bold', cursor: 'pointer'}}>Ateliere</button>
                <button onClick={() => handleSectionChange("spectacol")} style={{height: '2.5rem', width: '7rem', borderRadius: 30, color: '#923b16', fontWeight: 'bold', cursor: 'pointer'}}>Spectacol</button>
            </div>

            {activeSection === "ateliere" && ( 
            <div> 
                {atelierDetalii && Object.values(atelierDetalii).map((item, index) => (
                    <div className="container-atelier-left" key={index}>
                        <div className="image-container-atelier-left">
                            <img src={item.img} alt="" style={{width: imageWidth(), height: imageHeight()}}/>
                        </div>
                        <div className="information-container-atelier-left">
                            <h3 style={{fontSize: nameSize()}}>{item.title}</h3>
                            <h5 style={{fontSize: descriptionSize()}}>{item.descriere}</h5>
                        {item.name !== "" ? (
                            <a href={`/cv/${item.name}`} className="link-cv" style={{fontSize: cvSize()}}>
                            Spre CV coordonator
                            </a>
                        ) : null}
                        </div>
                    </div>
                    ))}
                    <div className="center aligned" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '5rem', marginTop: '5rem' }}>
                    <ReactPlayer 
                        url={Impact} 
                        // height={ xxl ? 550 : 500 } 
                        width={ playerWidth() }
                        controls={true}
                        playing={true}
                        loop={true}
                        muted={true}
                        onReady={() => true}
                    />
                </div> 
                    <ImageCarousel data={imageCarousel2} title={"Galerie"}/>
            </div> )}

            

            {activeSection === "spectacol" && ( 
            <div>
                <div className="container-atelier-left">
                    <div 
                        style={{
                            backgroundColor: primaryGrey,
                            display: xxl? '' : 'flex',
                            width: '80%',
                            margin: 'auto',
                            marginBottom: '10vh',
                        }}
                    >

                        <div className="left">
                            <img  src={showData?.src} alt={showData?.title} height={340} width={1000}/>
                        </div>

                        <div style={{textAlign: 'left', marginLeft: '2rem'}}>
                            <p style={{fontSize: 30}}>{showData?.title}</p>
                            <p className="description-a"> {showData?.description}</p>
                            <p className="description-a"> {showData?.description1}</p>
                            <p className="description-a"> {showData?.description2}</p>
                            <p className="description-a"> {showData?.description3}</p>
                            <p className="description-a"> {showData?.description4}</p>
                        </div>
                    </div>
                </div>

                <div className="center aligned" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '5rem' }}>
                    <ReactPlayer 
                        url={trailerExploziv} 
                        // height={ xxl ? 550 : 500 } 
                        width={ playerWidth() }
                        controls={true}
                        playing={true}
                        loop={true}
                        muted={true}
                        onReady={() => true}
                    />
                </div> 

                <ImageCarousel data={imageCarousel} title={"Galerie"}/>
           
                {/* ---------------------RECENZII ---------------------- */}
                    <h3
                        style={{
                            fontSize: '24px',
                            marginTop: '8rem'
                        }}
                    >
                        Recenzii
                    </h3>
                    <div className="testimonials-container">
                        <div className="post">
                            <div className="post-header">
                                <span className="post-title">Adriana Tomulețiu</span>
                            </div>
                            <div className="post-content" style={{whiteSpace: 'pre-line'}}>
                            {review1}
                            </div>
                        </div>
                    </div>
                    <div className="testimonials-container">
                        <div className="post">
                            <div className="post-header">
                                <span className="post-title">Codruța Pop</span>
                            </div>
                            <div className="post-content" style={{whiteSpace: 'pre-line'}}>
                            {review2}
                            </div>
                        </div>
                    </div>
                    <div className="testimonials-container" style={{marginBottom:'5rem'}}>
                        <div className="post">
                            <div className="post-header">
                                <span className="post-title">Mădălina Hondrea</span>
                            </div>
                            <div className="post-content" style={{whiteSpace: 'pre-line'}}>
                            {review3}
                            </div>
                        </div>
                    </div>

                </div>
            )}

                   

                <div style={{ width: '60%', margin: 'auto', marginBottom: '2rem', textAlign: 'center', marginTop: '2rem'}}>
                    <h5 style={{fontSize: descriptionSize()}}>Proiect co-finanțat de Municipiul Târgu Mureş în sesiunea 2023.</h5>
                    <h5 style={{fontSize: descriptionSize()}}>Proiectul nu reprezintă în mod necesar poziția Municipiului Târgu Mureş şi a Consiliului local care nu sunt responsabili de conţinutul proiectului sau de modul în care rezultatele acestuia pot fi folosite. Acestea sunt în întregime responsabilitatea beneficiarului finanţării”</h5>
                </div>
            
            <Footer />
        </div>

    )
}


export default Proiect;


const review1 = `Acest spectacol este unul cu adevărat ”exploziv”, care te zdruncină ca părinte. Te zdruncină atât de puternic, încât te trezește la realitate, îndemnându-te să te uiți în ”oglindă”, să te autoanalizezi: ”Ce fel de părinte sunt? Care sunt nevoile mele? Care este sensul meu? Care este atitudinea mea față de cei din jur, față de copiii mei? Oare sunt suficient de atent(ă) la nevoile lor? La trăirile lor? Sunt lângă ei atunci când au nevoie de mine? Sunt prezent(ă)?” 
Pentru că tot ceea ce suntem noi, ca adulți, ca părinți, toate trăirile, nevoile, traumele noastre, toate reacțiile și atitudinile noastre, își pun amprenta asupra copiilor noștri, asupra relației pe care o avem cu ei, uneori cu consecințe nefaste.

Este un spectacol ”exploziv”, care te zdruncină pe tine ca profesor și te îndeamnă să te interoghezi: Cum sunt eu ca profesor? Cum mă văd elevii mei? Oare cine sunt ei cu adevărat? Oare care sunt adevăratele lor nevoi? Ce trecut au, ce experiențe anterioare? Ce le place? Ce nu le place? Oare timpul petrecut aici, cu mine, este un timp de calitate pentru ei? Ceea ce i-am învățat le folosește cu adevărat în viață? Oare se simt în siguranță la școală? Și multe altele... 
Pentru că el, profesorul, nu este doar un transmițător de cunoștințe, un formator de competențe și abilități la elevi; e important să fie mai mult decât atât: să fie aproape de elevi, să-i ajute să înțeleagă valoarea educației, să-i facă să iubească școală, să le dezvolte încredere în ei, în resursele lor interioare, să le cultive optimismul, nu frica, starea de bine, nu stresul, să-i facă se simtă prețuiți pentru ceea ce sunt, exact așa cum sunt. El profesorul, nu lucrează cu materiale de construcții, unde poate se mai permit anumite rebuturi, care se pot remedia. 
Lucrează cu suflete, cu ființe umane, și un suflet odată distrus, este greu de recuperat.

Este un spectacol ”exploziv” pentru adolescenți; este ca un strigăt de ajutor: ”Sunt aici! Uita-te la mine! Mă vezi? Nu știi nimic despre mine!” Un strigăt de ajutor îndreptat spre părinți, spre profesori, spre colegi, spre societate în general. Este un strigăt care are rostul de a te trezi din indiferență, din nepăsare, din ”adormire”, care te îndeamnă să fii prezent, aici și acum, și să construiești, înainte de a fi prea târziu, o lume mai frumoasă, o lume mai bună.

Acest spectacol îl simt ca pe o lecție din care toți avem de învățat – părinți, profesori, elevi. O lecție pe care ne-au oferit-o 17 adolescenți minunați care, cu siguranță, și-au dedicat multe ore din timpul lor pentru a o pune în scenă! 

Este o lecție atât de bine scrisă de Elise Wilk, care reflectă probleme ale adolescenților, de la conflictele lor interioare până la conflictele cu cei din jur, nevoia lor de a ieși în evidență, de fi văzuți, ascultați, valorizați, nevoia de suport parental și psiho-educațional. Este o lecție despre viață, despre dorințe neîmplinite, despre drame umane, care te îndeamnă la reflecție. 

Este o lecție pe care cei 17 adolescenți au învățat-o alături de niște profesori minunați – actorii Anca Loghin și Rareș Budileanu – a căror implicare este, la rândul ei, o lecție pentru mulți dintre noi. Ați reușit prin acest proiect să ne arătați cât de valoroși sunt adolescenții, că interiorul lor e plin de resurse, iar dacă le scoți la iveală, le cultivi, le potențezi, creezi miracole. Și da, spectacolul ”Exploziv” este un miracol. 

Felicitări!`;


const review2 = `Viața netrăită a adulților este o grea povară aruncată pe umerii copiilor. Toate acele lucruri dosite în sertarele sufletului nostru, doruri, neîmpliniri, frustrări, dorințe înăbușite se revarsă grav tocmai asupra celor pentru care pretindem ca “ne sacrificăm”.

Piesa “Exploziv”, în regia lui Rareș Budileanu este, din punctul meu de vedere, un manifest! Exprimat prin teatru, pune pe tapet cele mai mari provocări ale adolescentului din generația “selfie" , “tik tok “ și “Nu am timp! Vorbim mâine” : uciderea creativității, sinuciderea, bullyng-ul, drogurile, indiferența, criza de identitate, dorința de apartenență și drama generată de lipsa ei.

O piesă care trebuie văzută de părinți și profesori în egală măsură. O piesă care intrigă, care schimbă, care trezește. Care ne arată ca singurele modele adevărate ale copiilor noștri suntem noi, adulții din jurul lor, care conducem prin puterea exemplului și mai puțin a cuvântului. 

Felicitări Rares Budileanu! Felicitări Anca Loghin! Felicitări adolescenților care au transmis în mai puțin de două ore ceea ce ne prefacem ca nu vedem de ani de zile!`;


const review3 = `Un spectacol foarte interesant, excelent jucat de copiii din proiect! S-a (intre)vazut munca din spatele lui, atât a protagoniștilor, cât și a celor care i-au îndrumat. Tema piesei este în mod cert de actualitate și ne amintește cât sunt de fragili și deopotrivă puternici adolescenții. Felicitări pentru proiect, Rares Budileanu și Anca Loghin!`;
