import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Image, Menu, Dropdown } from 'semantic-ui-react';
import HeaderLg from './HeaderLg';
import HeaderMb from './HeaderMb';
import Logo from '../../utils/GeneralImages/Logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { primaryGreen } from '../../utils/colors';
import { useNavigate, useLocation } from 'react-router-dom';
import './Header.css'

const HeaderComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeItem, setActiveItem] = useState("acasa");

    const handleItemClick = (e, { name }) => {
        setActiveItem(name);
        navigate(name === "acasa" ? "/" : `/${name}`);
    };

    const handleLogoClick = () => {
        navigate("/");
    };

    const handleFacebookClick = () => {
        window.open('https://www.facebook.com/incubator13treishpe', '_blank');
    };

    const handleInstagramClick = () => {
        window.open('https://www.instagram.com/incubator.13/', '_blank');
    };

    const none = useMediaQuery({ query: "(max-width:576px)" });
    const sm = useMediaQuery({ query: "(min-width:576px)" });
    const md = useMediaQuery({ query: "(min-width:1000px)" });
    const xl = useMediaQuery({ query: "(min-width:1200px)" });
    const xxl = useMediaQuery({ query: "(min-width:1400px)" });

    const size = { none, sm, md, xl, xxl };
    const isMobile = md || sm || none;

    const renderLinks = () => (
        <>
            <Menu.Item onClick={handleLogoClick} style={{ marginRight: xxl ? '20%' : xl ? '12%' : '7%' }}>
                <Image src={Logo} alt="Logo" size="small" style={{ cursor: 'pointer' }} />
            </Menu.Item>
            <Menu.Item name='acasa' id='acasa' active={activeItem === 'acasa'} onClick={handleItemClick} position={isMobile ? undefined : "right"} />
            <Dropdown item text='Proiect ExplozivART' name='proiect' active={activeItem === 'proiect'}>
                <Dropdown.Menu className='dd'>
                    <Dropdown.Item name='proiect-2024' onClick={() => handleItemClick(null, { name: 'proiect-2024' })}>2024</Dropdown.Item>
                    <Dropdown.Item name='proiect-2023' onClick={() => handleItemClick(null, { name: 'proiect-2023' })}>2023</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <Menu.Item name='evenimente' id="evenimente" active={activeItem === 'evenimente'} onClick={handleItemClick} />
            {/* <Menu.Item name='noaptea-muzeelor' id="noaptea-muzeelor" active={activeItem === 'noaptea-muzeelor'} onClick={handleItemClick} /> */}
            <Menu.Item name='echipa' id="echipa" active={activeItem === 'echipa'} onClick={handleItemClick} />
            <Menu.Item name='despre-noi' id="despre-noi" active={activeItem === 'despre-noi'} onClick={handleItemClick} />
            <Menu.Item name='contact' id="contact" active={activeItem === 'contact'} onClick={handleItemClick} />
            {size.md && (
                <Menu.Item className="social-links" position='right' style={{ width: '8%' }}>
                    <Menu.Item onClick={handleFacebookClick} style={{ padding: '0 5px' }}>
                        <FontAwesomeIcon icon={faFacebookF} size="2xl" style={{ backgroundColor: primaryGreen }} />
                    </Menu.Item>
                    <Menu.Item onClick={handleInstagramClick} style={{ padding: '0 5px' }}>
                        <FontAwesomeIcon icon={faInstagram} size="2xl" style={{ backgroundColor: primaryGreen }} />
                    </Menu.Item>
                </Menu.Item>
            )}
        </>
    );

    useEffect(() => {
        const path = location.pathname.substring(1);
        setActiveItem(path || "acasa");
    }, [location.pathname]);

    return (
        <div className='header-container' style={{ backgroundColor: '#73756f' }}>
            {size.md ?
                <HeaderLg renderLinks={renderLinks} /> :
                <HeaderMb renderLinks={renderLinks} />
            }
        </div>
    );
};

export default HeaderComponent;
