import Footer from '../../components/Footer/Footer';
import HeaderComponent from '../../components/Header/Header';

import { useMediaQuery } from 'react-responsive';

import { primaryGrey } from '../../utils/colors';

import './AboutUs.css';

import despreNoi from '../../utils/GeneralImages/desprenoi.jpg';

const AboutUs = () => {

    
    const sm = useMediaQuery({ query: "(max-width:400px)" })
    const sm2 = useMediaQuery({ query: "(max-width:500px)" })
    const md = useMediaQuery({ query: "(max-width:600px)" })
    const md2 = useMediaQuery({ query: "(max-width:800px)" })
    const xxl = useMediaQuery({ query: "(max-width:900px)" })
    const xxl2 = useMediaQuery({ query: "(min-width:1500px)" })

    const subTitleSize = () => {
        if(sm) { 
            return 12
        } else if(sm2) {
            return 14
        } else if(md2) {
            return 16
        } else if(xxl) {
            return 28
        } else if(xxl2) {
            return 30
        }
    }

    return (
        <div style={{ backgroundColor: primaryGrey}}>
            <HeaderComponent />

            <div className="about-us-container">

                <div className="image-section">
                    <img src={despreNoi} alt="about us"/>
                </div>

                <div className="info-section" style={{fontSize: subTitleSize(), width: '80%'}}>
                    <p>INCUBATOR 13 e munca activă a unor artiști/căutători care își doresc să țină comunitatea din Târgu-Mureș conectată la cultura alternativă. Ideea de Incubator13 s-a născut în 2012, în cadrul proiectului Practică Teatrală în Regiunile de Centru şi Nord-Vest Tg. Mureș, unde Rareș Budileanu a regizat, în premieră, textul "Pisica Verde" de Elise Wilk, câștigând premiul 1 pentru cel mai bun spectacol.  Din octombrie 2018, INCUBATOR 13 devine asociație cu drepturi depline și începe o serie de proiecte. Cele mai importante dintre ele - spectacolul “Săraci" de Lucy Thurber - în regia lui Rareș Budileanu, în co-producție cu Teatrul Național Tg. Mureș, a fost montat pentru prima dată în noiembrie 2019, Kaboom unlimited impro- spectacol de improvizație și ExplozivART – proiect de educație non formală 2023-2024. Din mai 2022, odată cu începerea activității în Turnul Porții din Cetatea Medievală Târgu-Mureș, INCUBATOR13 funcționează ca spațiu de colaborare și intersecție a numeroase tipuri de artă.

                    </p>
                </div>
            </div>

            <p className='left-p' style={{fontSize: subTitleSize(), width: '120%'}}>
            Pornind din dorința de a susține acte artistice , de a crea vizibilitate pentru performance-uri underground și de a naște colaborări cât mai diverse, din 2022 am pus în mișcare evenimentele a peste 100 de artiști locali & naționali: expoziții de pictură, desen, artă digitală, spectacolul de improvizație “KaBoom Unlimited Impro”( ajuns la ediția nr.32), spectacole-lectură, concerte alternative, ateliere de artă, ExplozivART - proiect de educație nonformală 2023/2024 ( co-finanțat de Primăria Tg. Mureș ), spectacole pentru adolescenți – Exploziv de Elise Wilk și Niște zile ciudate cu pisica verde (adaptare de texte după Elise Wilk). Și cum hărnicia s-a născut în târguri în care se mai pun mâini lângă mâini și se mai bat cuie și-n pereții care cad sau se ridică - chiar și-atunci când nu sunt ai tăi - am colaborat cu o serie de proiecte ale Târgului: Zacusca de Artă 2022 & 2023, Băla Fest 2023 /2024( co-organizatori la un mult îndrăgit festival boutique), Festivalul Luminii 2023, Școala de Primăvară “Nativ Creativ”2023, Zilele Târgumureșene 2022/2023, Noaptea muzeelor – 2022-2024. Ne dorim să atragem cât mai mulți artiști, oferindu-le un spațiu unde să își exprime lucrurile care le sunt importante. INCUBATOR13 e deschis oricărui fel de căutări și întâmplări artistice. Așteptăm și căutăm activ noi propuneri.
            </p>
            
            <Footer/>
        </div>
    );
}

export default AboutUs;
